import { Component, AfterViewInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AuthService } from '../../core/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements  AfterViewInit { 
  error: any;
  user;
  loginform: FormGroup;
  constructor(private fb: FormBuilder,private afAuth: AngularFireAuth, private _router: Router, private _authSrv: AuthService) {
    this.loginform = this.fb.group({
      email: [null,[Validators.pattern('^[a-zA-Z ]*$')]],
      password: [null, Validators.compose([Validators.required])]
  });
   }
  ngAfterViewInit(){
    this.getAuthStatus();

    if(this.afAuth.authState) {
      this._router.navigate(['/profiles']);
    }
  }
  getAuthStatus() {
    this._authSrv.redirectLogin().then(function(result) {
      console.log(result)
      if (result.credential) {
      }
    }).catch(
      (err) => {
        this.error = err;
      });
  }
  login(formData) {
    formData.email = formData.email+"@elhakoni.com";
    this._authSrv.login(formData)
    .then(
      (success) => {
        console.log(success)
        if (formData) {
          window.localStorage.setItem("email",formData.email);
          this._authSrv.user.subscribe(user => {
            if (user.role=='admin'){
              window.localStorage.setItem("role",'admin');
              window.localStorage.setItem("uid",success.user.uid);
            }
            else if (user.role=='employee'){
              window.localStorage.setItem("role",'employee');
              window.localStorage.setItem("uid",success.user.uid);
            }
            // location.reload(true);
              });
          };
      }).catch(
      (err) => {
        this.error = 'الرجاء ادخال اسم المستخدم وكلمة السر بشكل صحيح';
      }).then(r => {
        this._router.navigate(['/profiles']);
      }
      )
    ;
  }
  Register() {
    this._router.navigate(['/register']);
  }
}
