import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Plugins } from '@capacitor/core';
import { ToolsService } from './core/tools.service';

@Component({
  selector: 'body',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
 
  constructor(private router: Router,private tools: ToolsService) { }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.tools.getAllSelects().subscribe((select) => {
      const selectst = select.docs.map((e) => {
        return {
          ...e.data(),
        } as any;
      });
      const objcaseRelation = selectst[0].caseRelation;
      const arrOfCaseRelation = Object.keys(objcaseRelation).map((key) => ({
        key: Number(key),
        value: objcaseRelation[key],
      }));
      Plugins.Storage.set({
        key: 'caseRelation',
        value: JSON.stringify(arrOfCaseRelation),
      });
      const objCaseHealth = selectst[0].caseHealth;
      const arrOfCaseHealth = Object.keys(objCaseHealth).map((key) => ({
        key: Number(key),
        value: objCaseHealth[key],
      }));
      Plugins.Storage.set({
        key: 'caseHealth',
        value: JSON.stringify(arrOfCaseHealth),
      });
      const objcaseeducation = selectst[0].caseEducation;
      const arrOfCaseEducation = Object.keys(objcaseeducation).map((key) => ({
        key: Number(key),
        value: objcaseeducation[key],
      }));
      Plugins.Storage.set({
        key: 'caseEducation',
        value: JSON.stringify(arrOfCaseEducation),
      });
      const objcaseeducationtype = selectst[0].educationType;
      const arrOfCaseEducationType = Object.keys(
        objcaseeducationtype
      ).map((key) => ({ key: Number(key), value: objcaseeducationtype[key] }));
      Plugins.Storage.set({
        key: 'educationType',
        value: JSON.stringify(arrOfCaseEducationType),
      });
      const objliving = selectst[0].living;
      const arrOfLiving = Object.keys(objliving).map((key) => ({
        key: Number(key),
        value: objliving[key],
      }));
      Plugins.Storage.set({
        key: 'living',
        value: JSON.stringify(arrOfLiving),
      });
      const objcasehealthtype = selectst[0].caseHealthType;
      const caseHealthType = Object.keys(objcasehealthtype).map((key) => ({
        key: Number(key),
        value: objcasehealthtype[key],
      }));
      Plugins.Storage.set({
        key: 'caseHealthType',
        value: JSON.stringify(caseHealthType),
      });
      const objcaseages = selectst[0].ages;
      const arrages = Object.keys(objcaseages).map((key) => ({
        key: Number(key),
        value: objcaseages[key],
      }));
      Plugins.Storage.set({
        key: 'ages',
        value: JSON.stringify(arrages),
      });
      const objcategories = selectst[0].categories;
      const arrOfcategories = Object.keys(objcategories).map((key) => ({
        key: Number(key),
        value: objcategories[key],
      }));
      Plugins.Storage.set({
        key: 'categories',
        value: JSON.stringify(arrOfcategories),
      });
      const objmaritals = selectst[0].maritals;
      const arrOfmaritals = Object.keys(objmaritals).map((key) => ({
        key: Number(key),
        value: objmaritals[key],
      }));
      Plugins.Storage.set({
        key: 'maritals',
        value: JSON.stringify(arrOfmaritals),
      });
      const objeducation = selectst[0].educations;
      const arrOfeducations = Object.keys(objeducation).map((key) => ({
        key: Number(key),
        value: objeducation[key],
      }));
      Plugins.Storage.set({
        key: 'educations',
        value: JSON.stringify(arrOfeducations),
      });
      const objcategoryBooks = selectst[0].booksCategoryFilter;
      const arrOfcategoryBooks = Object.keys(objcategoryBooks).map((key) => ({
        key: Number(key),
        value: objcategoryBooks[key],
      }));
      Plugins.Storage.set({
        key: 'booksCategoryFilter',
        value: JSON.stringify(arrOfcategoryBooks),
      });
      const objcategoryconsults = selectst[0].consultsCategoryFilter;
      const arrOfcategoryConsults = Object.keys(
        objcategoryconsults
      ).map((key) => ({ key: Number(key), value: objcategoryconsults[key] }));
      Plugins.Storage.set({
        key: 'consultsCategoryFilter',
        value: JSON.stringify(arrOfcategoryConsults),
      });
      const objcategoryeducational = selectst[0].educationalCategoryFilter;
      const arrOfcategoryEducational = Object.keys(
        objcategoryeducational
      ).map((key) => ({ key: Number(key), value: objcategoryeducational[key] }));
      Plugins.Storage.set({
        key: 'educationalCategoryFilter',
        value: JSON.stringify(arrOfcategoryEducational),
      });

      const objcategoryMarrige = selectst[0].marrige;
      const arrOfcategoryMarrige = Object.keys(
        objcategoryMarrige
      ).map((key) => ({ key: Number(key), value: objcategoryMarrige[key] }));
      Plugins.Storage.set({
        key: 'marrigeCategoryFilter',
        value: JSON.stringify(arrOfcategoryMarrige),
      });
      const objcategoryGame = selectst[0].gamesCategoryFilter;
      const arrOfcategoryGames = Object.keys(
        objcategoryGame
      ).map((key) => ({ key: Number(key), value: objcategoryGame[key] }));
      Plugins.Storage.set({
        key: 'gamesCategoryFilter',
        value: JSON.stringify(arrOfcategoryGames),
      });
    });

  }
  
  }

