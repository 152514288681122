// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.


export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCaPvbN1izFSooQFPHec3QJjCULWqwdPIM",
    authDomain: "helpme-6b560.firebaseapp.com",
    databaseURL: "https://helpme-6b560.firebaseio.com",
    projectId: "helpme-6b560",
    storageBucket: "helpme-6b560.appspot.com",
    messagingSenderId: "1025259997404",
    appId: "1:1025259997404:web:e564693d527dd6a771cc19",
    measurementId: "G-5JYNG3RE8N"
  }
};