import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Plugins } from '@capacitor/core';


@Injectable({
  providedIn: 'root',
})
export class ToolsService {
  constructor(private firestore:AngularFirestore) {}
  async getSelectsFromStorage(key: string): Promise<{ value: any }> {
    const ret = await Plugins.Storage.get({ key });
    return JSON.parse(ret.value);
  }
  getOperation(key = 0) {
    const list = [
      { key: 1, value: 'إضافة حالة' },
      { key: 2, value: 'معالجة الحالة' },
      { key: 3, value: 'اضافة تعليق' },
      { key: 4, value: 'توجيه الحالة' },
      { key: 5, value: 'الرد على التعليق' },
      { key: 6, value: 'إغلاق الحالة' },
      { key: 7, value: 'مشترك جديد' },
      { key: 8, value: ' الرد على الاستشارة' },
      { key: 9, value: 'تجهيز استشارة للمراجعة' },
      { key: 10, value: '' },
    ];
    if (key) {
      return list.filter((x) => x.key === key)[0].value;
    } else {
      return list;
    }
  }
  getRequestStatus(key = 0) {
    const list =[{key:1,value:"الحالات الموجهة للموظفين"},{key:2,value:"الحالات الموجهة للموظفين والمطلوب تعديلها من الادارة"},
    {key: 3,value:"الحالات الموجهة للادارة"},
    {key:4,value:"الحالات التي بانتظار تعديل أو موافقة من قبل الادارة"},{key:10,value:"الحالات المعتمدة"},
    {key:20,value:"الحالات المنتهية"}
  ]
    if (key) {
      return list.filter((x) => x.key === key)[0];
    } else {
      return list;
    }
  }
  getCauserType(key = 0) {
    const list = [
      { key: 1, value: 'مشترك' },
      { key: 2, value: 'الادارة' },
      { key: 3, value: 'موظف' },
    ];
    if (key) {
      return list.filter((x) => x.key === key)[0].value;
    } else {
      return list;
    }
  }
  getStatus(key = 0) {
    const list = [
      { key: 1, value: 'تم استلام الحالة' , color: 'tertiary' , icon: 'checkmark-circle-outline'},
      { key: 2, value: 'قيد المعالجة' , color: 'warning' , icon: 'timer-outline'},
      { key: 3, value: 'تمت معالجة الحالة' , color: 'success' , icon: 'thumbs-up-outline'},
    ];
    if (key) {
      return list.filter((x) => x.key === key)[0];
    } else {
      return list;
    }
  }

  getAllSelects(){
    return this.firestore.collection('selects').get();

  }
  getLevel(key = 0) {
    const list = [
      { key: 1, value: 'استشارة طارئة'},
      { key: 2, value: 'استشارة مستعجلة'},
      { key: 3, value: 'استشارة عادية'},
    ];
    if (key) {
      return list.filter((x) => x.key === key)[0];
    } else {
      return list;
    }
  }

  getCategories(categories,key = 0) {
    // const list = [
    //   { key: 1, value: 'امرأة' },
    //   { key: 2, value: 'رجل' },
    //   { key: 3, value: 'شاب' },
    //   { key: 4, value: 'فتاة' },
    //   { key: 5, value: 'جد' },
    //   { key: 6, value: 'جدة' },
    // ];
    const list=categories
    if (key) {
      return list.filter((x) => x.key === key)[0].value;
    } else {
      return list;
    }
  }
  getAges(ages,key = 0) {
    // const list = [
    //   { key: 1, value: 'من 14 حتى 24' },
    //   { key: 2, value: 'من 25 حتى 40' },
    //   { key: 3, value: 'من 41 حتى 60' },
    //   { key: 4, value: 'من 60 و ما فوق' },
    // ];
    const list=ages
    if (key) {
      return list.filter((x) => x.key === key)[0].value;
    } else {
      return list;
    }
  }
  getMaritals(maritals,key = 0) {
    // const list = [
    //   { key: 1, value: 'اعزب' },
    //   { key: 2, value: 'متزوج' },
    //   { key: 3, value: 'مطلق' },
    //   { key: 4, value: 'ارمل' },
    // ];
    const list=maritals
    if (key) {
      return list.filter((x) => x.key === key)[0].value;
    } else {
      return list;
    }
  }

  getEducations(educations,key = 0) {
    // const list = [
    //   { key: 1, value: 'ابتدائي' },
    //   { key: 2, value: 'متوسط' },
    //   { key: 3, value: 'ثانوي' },
    //   { key: 4, value: 'جامعي' },
    //   { key: 5, value: 'ماجيستير' },
    //   { key: 6, value: 'دكتوراه' },
    // ];
    const list=educations
    if (key) {
      return list.filter((x) => x.key === key)[0].value;
    } else {
      return list;
    }
  }
  // getCaseRelation( key = 0) {
  //   const list = [
  //     {key:21, value:'أنا'} ,
  //     { key: 1, value: 'أبي' },
  //     { key: 2, value: 'أمي' },
  //     { key: 3, value: 'أخي' },
  //     { key: 4, value: 'أختي' },
  //     { key: 5, value: 'معلمي' },
  //     { key: 6, value: 'معلمتي' },
  //     { key: 7, value: 'حفيدي ' },
  //     { key: 8, value: 'حفيدتي' },
  //     { key: 9, value: 'قريبي' },
  //     { key: 10, value: 'علاقة عمل' },
  //     { key: 11, value: 'ابنتي' },
  //     { key: 12, value: 'ابني' },
  //     { key: 13, value: 'زوجتي' },
  //     { key: 14, value: 'صديقي' },
  //     { key: 15, value: 'خطيبتي' },
  //     { key: 16, value: 'جاري' },
  //     { key: 17, value: 'زوجي' },
  //     { key: 18, value: 'صديقتي' },
  //     { key: 19, value: 'خطيبي' },
  //     { key: 20, value: 'جارتي' }
      
  //   ];
  //   if (key) {
  //     return list.filter((x) => x.key === key)[0];
  //   }else{
  //     return list;
  //   } 
      
  // }
  getCaseRelation(caseRelation, catId, key = 0) {
    const list = caseRelation;
    if (key) {
      return list.filter((x) => x.key === key)[0].value;
    } else {
      if ([1, 4, 6].includes(catId)) {
        const temp = list.filter((item) => {
          try {
            return ![13, 14, 15, 16].includes(item.key);
          } catch {}
        });
        return temp;
      } else {
        const temp2 = list.filter((item) => {
          try {
            return ![17, 18, 19, 20].includes(item.key);
          } catch {}
        });
        return temp2;
      }
    }
  }
  // getCaseHealth(key = 0) {
  //   const list = [
  //     { key: 1, value: 'سليم' },
  //     { key: 2, value: 'يعاني من مرض ' },
  //     { key: 3, value: 'يعاني من اضطرابات نفسية' },
  //     { key: 4, value: 'من ذوي الاحتيات الخاصة' },
  //   ];
  //   if (key) {
  //     return list.filter((x) => x.key === key)[0];
  //   } else {
  //     return list;
  //   }
  // }
  getCaseHealth(caseHealth, key = 0) {
    const list = caseHealth;
    if (key) {
      return list.filter((x) => x.key === key)[0].value;
    } else {
      return list;
    }
  }
  // getCaseHealthType(key=0){
  //   const list = [
  //     { key: 1, value: 'سكر' },
  //     { key: 2, value: ' ضغط ' },
  //     { key: 3, value: 'القلب' },
  //     { key: 4, value: 'ربو' },
  //     { key: 5, value: 'تكسر في الدم' },
  //     { key: 6, value: 'صرع' },
  //     { key: 7, value: 'حساسية جلد' },
  //     { key: 8, value: 'سرطان' },
  //     { key: 9, value: 'كُلى' },
  //     { key: 10, value: 'تبوّل لا إرادي' },
  //     { key: 11, value: 'اكتئاب' },
  //     { key: 12, value: 'قلق' },
  //     { key: 13, value: 'وسواس قهري' },
  //     { key: 14, value: 'فصام' },
  //     { key: 15, value: 'رهاب اجتماعي' },
  //     { key: 16, value: 'اضطراب النوم' },
  //     { key: 17, value: 'عسر قراءة' },
  //     { key: 18, value: 'فرط حركة' },
  //     { key: 19, value: 'قلة تركيز' },
  //     { key: 20, value: 'طيف توحّد' },
  //     { key: 21, value: 'توحّد' },
  //     { key: 22, value: 'بطء تعلّم' },
  //     { key: 23, value: 'تخلّف عقلي' },
  //     { key: 24, value: 'شلل أطراف' },
  //     { key: 25, value: 'شلل نصفي' },
  //     { key: 26, value: 'صم بكم' },
  //     { key: 27, value: 'كفيف' },
  //     { key: 28, value: 'بتر أطراف' },
  //     { key: 29, value: ' أخرى أذكرها' },

  //   ];
  //   if (key) {
  //     return list.filter((x) => x.key === key)[0];
  //   } else {
     
  //       return list;
  //     }
      
  //   }

  getCaseHealthType(casehealthtype, casehealth, key = 0) {
    const list = casehealthtype;
    if (key) {
      return list.filter((x) => x.key === key)[0].value;
    } else {
      if ([2].includes(casehealth)) {
        const temp = list.filter((item) => {
          try {
            return ![
              11,
              12,
              13,
              14,
              15,
              16,
              17,
              18,
              19,
              20,
              21,
              22,
              23,
              24,
              25,
              26,
              27,
              28,
            ].includes(item.key);
          } catch {}
        });
        return temp;
      } else if ([3].includes(casehealth)) {
        const temp2 = list.filter((item) => {
          try {
            return ![
              1,
              2,
              3,
              4,
              5,
              6,
              7,
              8,
              9,
              10,
              17,
              18,
              19,
              20,
              21,
              22,
              23,
              24,
              25,
              26,
              27,
              28,
            ].includes(item.key);
          } catch {}
        });
        return temp2;
      } else if ([4].includes(casehealth)) {
        const temp3 = list.filter((item) => {
          try {
            return ![
              1,
              2,
              3,
              4,
              5,
              6,
              7,
              8,
              9,
              10,
              11,
              12,
              13,
              14,
              15,
              16,
            ].includes(item.key);
          } catch {}
        });
        return temp3;
      } else {
        return list;
      }
    }
  }
  // getCaseEducation( key = 0) {
  //   const list = [
  //     { key: 1, value: 'روضة' },
  //     { key: 2, value: 'ابتدائي' },
  //     { key: 3, value: 'متوسط' },
  //     { key: 4, value: 'ثانوي' },
  //     { key: 5, value: 'جامعي' },
  //     { key: 6, value: 'تعليم عالي' },
  //   ];
  //   if (key) {
  //     return list.filter((x) => x.key === key)[0];
  //   } else {
  //       return list;
  //     }
  // }
  getCaseEducation(caseEducation, relation, key = 0) {

    const list = caseEducation;
    if (key) {
      return list.filter((x) => x.key === key)[0].value;
    } else {
      if (![3, 4, 7, 8, 11, 12].includes(relation)) {
        const temp = list.filter((item) => {
          try {
            return ![1, 2].includes(item.key);
          } catch {}
        });
        return temp;
      } else {
        return list;
      }
    }
  }
  // getEducationType( key = 0) {
  //   const list = [
  //     { key: 1, value: 'خاص' },
  //     { key: 2, value: 'حكومي' },
  //     { key: 3, value: 'ثنائي اللغة' },
  //     { key: 4, value: 'منزلي' },
  //     { key: 5, value: ' افتراضي' },
  //     { key: 6, value: ' شرعي' },
  //     { key: 7, value: ' نظامي' },
  //     { key: 8, value: ' كتاب' },
  //   ];
  //   if (key) {
  //     return list.filter((x) => x.key === key)[0];
  //   }else  {
  //       return list;
  //     }
    
  // }
  getEducationType(educationType, relation, key = 0) {
    const list = educationType;
    if (key) {
      return list.filter((x) => x.key === key)[0].value;
    } else {
      if (![3, 4, 7, 8, 11, 12].includes(relation)) {
        const temp = list.filter((item) => {
          try {
            return ![1, 2, 3, 4].includes(item.key);
          } catch {}
        });
        return temp;
      } else {
        return list;
      }
    }
  }
  // getLiving( key = 0) {
  //   const list = [
  //     { key: 1, value: 'معي' },
  //     { key: 2, value: 'مع والده' },
  //     { key: 3, value: 'مع والدته' },
  //     { key: 4, value: 'مع جدته' },
  //     { key: 5, value: 'مع جده' },
  //     { key: 6, value: 'مع اقاربه' },
  //     { key: 7, value: 'في نفس البيت' },
  //     { key: 8, value: 'في نفس البلد' },
  //     { key: 9, value: 'في بيت اخر' },
  //     { key: 10, value: 'مسافر' },
  //     { key: 11, value: 'مصح' },
  //     { key: 12, value: 'في بيته' },
  //     { key: 13, value: 'في السجن' },
  //   ];
  //   if (key) {
  //     return list.filter((x) => x.key === key)[0]
  //   } else {
  //       return list;
  //   }
  // }
  getLiving(living, relation, key = 0) {
    const list = living;
    if (key) {
      return list.filter((x) => x.key === key)[0].value;
    } else {
      if (![3, 4, 7, 8, 11, 12].includes(relation)) {
        const temp = list.filter((item) => {
          try {
            return ![1, 2, 3, 4, 5, 6].includes(item.key);
          } catch {}
        });
        return temp;
      } else {
        return list;
      }
    }
  }
 
  // getConsultsCategory( key = 0) {
  //   const list = [
  //     { key: 1, value: 'شخصية' },
  //     { key: 2, value: 'والدية' },
  //     { key: 3, value: 'أسرية' },
  //     { key: 4, value: 'صحبية' },
  //     { key: 5, value: ' جنسية' },
  //     { key: 6, value: ' نفسية' },
  //     { key: 7, value: ' فكرية' },
  //     { key: 8, value: ' شرعية' },
  //     { key: 9, value: ' حرفية' },
  //     { key: 10, value: ' صحية' },
  //     { key: 11, value: ' دراسية' },

  //   ];
  //   if (key) {
  //     return list.filter((x) => x.key === key)[0];
  //   }else  {
  //       return list;
  //     }
    
  // }
  // getEducationalCategory( key = 0) {
  //   const list = [
  //     { key: 1, value: 'عامة' },
  //     { key: 2, value: 'للوالدين' },
  //     { key: 3, value: 'للمشرفين' },
  //     { key: 4, value: 'للمدربين' },
  //     { key: 5, value: ' للمتربين' },
  //     {key:6,value:'المعلمين والمدربين'},
  //     {key:7,value:'قواعد خاصة'}


  //   ];
  //   if (key) {
  //     return list.filter((x) => x.key === key)[0];
  //   }else  {
  //       return list;
  //     }
    
  // }
  // getBooksCategory( key = 0) {
  //   const list = [
  //     { key: 1, value: 'الاسرة' },
  //     { key: 2, value: 'الفتيان' },
  //     { key: 3, value: 'الفتيات' },
  //     { key: 4, value: 'المربين' },
  //     { key: 5, value: ' عامة' },
  //   ];
  //   if (key) {
  //     return list.filter((x) => x.key === key)[0];
  //   }else  {
  //       return list;
  //     }
    
  // }
  getBooks(books, key = 0) {
    const list = books;
    if (key) {
      return list.filter((x) => x.key === key)[0].value;
    } else {
      return list;
    }
  }
  getConsults(consults,key=0){
    const list = consults;
    if (key) {
      return list.filter((x) => x.key === key)[0].value;
    } else {
      return list;
    }
  }
  getEducationals(educational,key=0){
    const list = educational;
    if (key) {
      return list.filter((x) => x.key === key)[0].value;
    } else {
      return list;
    }
  }
  getMarrige(marrige,key=0){
    const list = marrige;
    if (key) {
      return list.filter((x) => x.key === key)[0].value;
    } else {
      return list;
    }
  }
  getNumber(str) {
    return Number(str);
  }
  arrayOfNumber(num) {
    return Array(num)
      .fill(0)
      .map((x, i) => i);
  }


  getCountriesCode(){
   return [    {
    
      name: "اسبانيا",
      dial_code: "+34",
      code: "ES"
    },


    {
      name: "استراليا",
      dial_code: "+61",
      code: "AU"
    }, {
      name: "الأرجنتين",
      dial_code: "+54",
      code: "AR"
    },
    {
      name: "الاردن",
      dial_code: "+962",
      code: "JO"
    },
    {
      name: "الاكوادور",
      dial_code: "+593",
      code: "EC"
    }, 
  {
  name: "الامارات",
  dial_code: "+971",
  code: "AE"
},
{
  name: "البحرين",
  dial_code: "+973",
  code: "BH"
},
{
  name: "البرازيل",
  dial_code: "+55",
  code: "BR"
},
{
  name: "البرتغال",
  dial_code: "+351",
  code: "PT"
},
{
  name: "الجزائر",
  dial_code: "+213",
  code: "DZ"
},

{
  name: "الدنمارك",
  dial_code: "+45",
  code: "DK"
}, 

{
  name: "السودان",
  dial_code: "+249",
  code: "SD"
},
{
  name: "السويد",
  dial_code: "+46",
  code: "SE"
}, 
{
  name: "الصين",
  dial_code: "+86",
  code: "CN"
}, 
{
  name: "العراق",
  dial_code: "+964",
  code: "IQ"
},
{
  name: "الفلبين",
  dial_code: "+63",
  code: "PH"
},{
  name: "الكويت",
  dial_code: "+965",
  code: "KW"
}, 
{
  name: "المانيا",
  dial_code: "+49",
  code: "DE"
}, 
{
  name: "السعودية ",
  dial_code: "+966",
  code: "SA"
},
{
  name: "المغرب",
  dial_code: "+212",
  code: "MA"
},
{
  name: "المملكة المتحدة",
  dial_code: "+44",
  code: "GB"
},  

{
  name: "النرويج",
  dial_code: "+47",
  code: "NO"
},
{
  name: "الهند",
  dial_code: "+91",
  code: "IN"
},
{
  name: "اليابان",
  dial_code: "+81",
  code: "JP"
},
{
  name: "اليمن",
  dial_code: "+967",
  code: "YE"
}, 
{
  name: "اليونان",
  dial_code: "+30",
  code: "GR"
}, 
{
  name: "اندونيسيا",
  dial_code: "+62",
  code: "ID"
}, 
{
  name: "أميركا",
  dial_code: "+1",
  code: "US"
},
{
  name: "اوكرانيا",
  dial_code: "+380",
  code: "UA"
},
{
  name: "ايران",
  dial_code: "+98",
  code: "IR"
},
{
  name: "ايسلندا",
  dial_code: "+354",
  code: "IS"
}, 
{
  name: "ايطاليا",
  dial_code: "+39",
  code: "IT"
},
{
  name: "بلغاريا",
  dial_code: "+359",
  code: "BG"
},

{
  name: "تايلندا",
  dial_code: "+66",
  code: "TH"
}, 
{
  name: "تركيا",
  dial_code: "+90",
  code: "TR"
}, 
{
  name: "تونس",
  dial_code: "+216",
  code: "TN"
},
{
  name: "جورجيا",
  dial_code: "+995",
  code: "GE"
},
{
  name: "روسيا",
  dial_code: "+7",
  code: "RU"
},
{
  name: "سلطنة عمان",
  dial_code: "+968",
  code: "OM"
},
{
  name: "سوريا",
  dial_code: "+963",
  code: "SY"
},  
{
  name: "سويسرا",
  dial_code: "+41",
  code: "CH"
},
{
  name: "فرنسا",
  dial_code: "+33",
  code: "FR"
},
{
  name: "فنزويلا",
  dial_code: "+58",
  code: "VE"
},
{
  name: "فنلندا",
  dial_code: "+358",
  code: "FI"
},  
{
  name: "قبرص",
  dial_code: "+537",
  code: "CY"
},
{
  name: "قطر",
  dial_code: "+974",
  code: "QA"
}, 
{
  name: "كرواتيا",
  dial_code: "+385",
  code: "HR"
}, 
{
  name: "كمبوديا",
  dial_code: "+855",
  code: "KH"
},
{
  name: "كندا",
  dial_code: "+1",
  code: "CA"
},
{
  name: "كولومبيا",
  dial_code: "+57",
  code: "CO"
}, 
{
  name: "كوبا",
  dial_code: "+53",
  code: "CU"
},  
{
  name: "لبنان",
  dial_code: "+961",
  code: "LB"
},
{
  name: "ليبيا",
  dial_code: "+218",
  code: "LY"
}, 
{
  name: "ماليزيا",
  dial_code: "+60",
  code: "MY"
}, 
{
  name: "مصر",
  dial_code: "+20",
  code: "EG"
}, 
{
  name: "هولندا",
  dial_code: "+31",
  code: "NL"
}, 
{
  name: "هنغاريا",
  dial_code: "+36",
  code: "HU"
}

   ]
  }
  getErrorMEssage(key=''){
    const list = [
      { key: 'TOO_LONG', value: 'طويل جدا' },
      { key: 'Invalid format.', value: ' طريقة ادخال الرقم غير صالحة ' },
      { key: 'The SMS verification code used to create the phone auth credential is invalid. Please resend the verification code sms and be sure use the verification code provided by the user.'
      , value: 'رمز التحقق SMS المستخدم لإنشاء بيانات اعتماد مصادقة الهاتف غير صالح. يرجى إعادة إرسال رمز التحقق SMS والتأكد من استخدام رمز التحقق الذي قدمه المستخدم.' },
      { key: 'We have blocked all requests from this device due to unusual activity. Try again later.'
      , value: 'لقد حظرنا جميع الطلبات الواردة من هذا الجهاز بسبب نشاط غير عادي. حاول مرة أخرى في وقت لاحق.' },
      { key: 'A network error (such as timeout, interrupted connection or unreachable host) has occurred.', value: 'حدث خطأ في الشبكة (مثل المهلة أو الاتصال المتقطع أو المضيف الذي لا يمكن الوصول إليه).' },
      { key: 'The SMS code has expired. Please re-send the verification code to try again.', value: 'انتهت صلاحية رمز SMS. يرجى إعادة إرسال رمز التحقق للمحاولة مرة أخرى.' },
      { key: 'Invalid token.', value: 'رمز غير صالح.' },
      { key: 'The format of the phone number provided is incorrect. Please enter the phone number in a format that can be parsed into E.164 format. E.164 phone numbers are written in the format [+][country code][subscriber number including area code]. [ TOO_SHORT ]', value: 'تنسيق رقم الهاتف المقدم غير صحيح. الرجاء إدخال رقم الهاتف بتنسيق يمكن تحليله بتنسيق E.164. تتم كتابة أرقام هواتف E.164 بالتنسيق [+] [رمز الدولة] [رقم المشترك بما في ذلك رمز المنطقة]. [ قصيرة جدا ]' }

    ];
    if (key) {
      return list.filter((x) => x.key === key)[0];
    } else {
     
        return list;
      }
      
    }
  
}
    




























 






 

