import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AuthService } from '../../core/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
@Component({
  selector: 'app-dashboard',
  templateUrl: 'register.component.html'
})
export class RegisterComponent {
  error: any;
  constructor(private afs: AngularFirestore, private _router: Router, private _authSrv: AuthService,private authservice:AuthService) { }
  register(formData?) {
    this._authSrv.signUp(formData).then((credential)=>{
      const collection = this.afs.collection("users")
      const userID = credential.user.uid
      collection.doc(userID).set({
          roles:"employee" // some another information for user you could save it here.
      }).then(() => {
        this._router.navigate(['/profiles']);
        this.authservice.updateUserData(credential.user)
      });
    });
  }
}
