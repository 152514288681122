import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { AuthGuard } from './core/auth.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  // // ,
  // // {
  // //   path: 'register',
  // //   component: RegisterComponent,
  // //   data: {
  // //     title: 'Register Page'
  // //   }
  // },
  
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    canActivate:[AuthGuard],
    children: [
     
      {
        path: 'users',
        loadChildren: './views/users/users.module#BaseModule'
      },
      {
        path:'Requests',
        loadChildren:'./views/Requests/requests.module#BaseModule'
      },
      {
        path:'paymentHistory',
        loadChildren:'./views/paymentHistory/paymentHistory.module#BaseModule'
      },
      {
        path:'details/:id',
        loadChildren:'./views/details/details.module#BaseModule'
      },
      {
        path:'archiveCases',
        loadChildren:'./views/archiveCases/archiveCases.module#BaseModule'
      },
      {
        path:'allCases',
        loadChildren:'./views/allCases/allCases.module#BaseModule'
      }, {
        path:'endedCases',
        loadChildren:'./views/endedCases/endedCases.module#BaseModule'
      },
      {
        path:'activity',
        loadChildren:'./views/activity/activity.module#BaseModule'
      },
      {
        path:'profiles',
        loadChildren:'./views/profiles/profiles.module#BaseModule'
      },
      {
        path:'logInErrorLogs',
        loadChildren:'./views/logInErrorLogs/logInErrorLogs.module#BaseModule'
      },
      {
        path:'profileDetails/:id',
        loadChildren:'./views/profileDetails/profileDetails.module#BaseModule'
      },
      {
        path:'profileCases/:id',
        loadChildren:'./views/profileCases/profileCases.module#BaseModule'
      }
      ,{
        path: 'consults',
        loadChildren: './views/consults/consults.module#BaseModule'
      }
      ,{
        path: 'educational',
        loadChildren: './views/educational/educational.module#BaseModule'
      }
      ,{
        path: 'marrige',
        loadChildren: './views/marrige/marrige.module#BaseModule'
      }
      ,{
        path: 'games',
        loadChildren: './views/games/games.module#BaseModule'
      }
      ,{
        path: 'books',
        loadChildren: './views/books/books.module#BaseModule'
      }
 
    ]
  }
];

@NgModule({ 
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
