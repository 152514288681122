import {Component, OnDestroy} from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { Cases, AfsService, comments, activityLogs } from '../../core/afs.service';
import { Router } from '@angular/router';
import { async } from 'rxjs/internal/scheduler/async';
@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnDestroy {
  public navitems ;
  public sidebarMinimized= true;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;
   myCases:Cases[]
    user;
    uid;
    casesIds=[]
  public myCommentsLogs:activityLogs[]
 public  myCasesLogs:activityLogs[]
 orgMyCommentsLogs:activityLogs[]
 orgMyCasesLogs:activityLogs[]
  allCommentsLogs: activityLogs[];
  orgallCommentsLogs: activityLogs[];
  allRequestsCasesLogs: activityLogs[];
  allNewProfiles: activityLogs[];
  allConsultations: activityLogs[];
  orgAllConsultations: activityLogs[];
  role;
  constructor(private router: Router,public authSrv:AuthService ,private afsSrv: AfsService) {
    }
  ngOnDestroy(): void {
    this.changes.disconnect();
  }
  ngOnInit() {
    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = document.body.classList.contains('sidebar-minimized');
    });
    this.changes.observe(<Element>this.element, {
      attributes: true,
      attributeFilter: [ 'class' ]
    });
    this.uid = window.localStorage.getItem("uid");
    this.role = window.localStorage.getItem("role");
    console.log(this.role);
    this.setNavItem();
    this.authSrv.user.subscribe(u => {
      this.user = u;
    });
  }
   ngAfterViewInit () {
    this.afsSrv.getActivityLogsAllRequestsCases().subscribe(data => {
      this.allRequestsCasesLogs = data.map(e => {
        return{
          id: e.payload.doc.id,
          ...e.payload.doc.data() as {}
        } as activityLogs;
      });
    });
    this.afsSrv.getAllActivityLogs().subscribe(data => {
      this.allCommentsLogs = this.orgallCommentsLogs = data.map(e => {
        return{
          id: e.payload.doc.id,
          ...e.payload.doc.data() as {}
        }as activityLogs;
      });
      this.allCommentsLogs = this.orgallCommentsLogs.filter(r => {
        if (r.operation==3 ||r.operation==5) {
          return true;
        }
        return false;
      });
      this.allConsultations = this.orgAllConsultations = data.map(e => {
        return{
          id:e.payload.doc.id,
          ...e.payload.doc.data() as {}
        }as activityLogs;
      }).filter(r => {
        if(r.readed == false) {
          if (r.operation == 9){
            return true;
          }
          return false;
        }
      });
    });
    this.afsSrv.getActivityLogsAllNewProfiles().subscribe(data => {
      this.allNewProfiles = data.map(e => {
        return{
          id: e.payload.doc.id,
          ...e.payload.doc.data() as {}
        } as activityLogs;
      });
    });
    this.afsSrv.getMyCasesstatussnapshotChanges(this.uid).subscribe(data=>{
      this.myCases=data.map( e => {
        return{
         id:e.payload.doc.id,
          ...e.payload.doc.data() as {}
          }as Cases;
         });
         this.myCases.forEach(r=>{
           this.casesIds.push(r.id)
         })
         this.renderCasesIds(this.casesIds)
       })
  }
  renderCasesIds(casesIds){
    console.log(casesIds)
    this.afsSrv.getActivityLogsForComments().subscribe( data => {
      this.myCommentsLogs=this.orgMyCommentsLogs=data.map(e=>{
        return{
          id:e.payload.doc.id,
          ...e.payload.doc.data() as {}}  as activityLogs
      })
      // console.log(this.myCommentsLogs)
         this.myCommentsLogs=this.orgMyCommentsLogs.filter(x=>{
          //  console.log(x)
           if(casesIds.includes(x.caseId)){
            //  console.log(x)
            return true;
           }else{
             return false;
           }
         } )
    })
    this.afsSrv.getActivityLogsForMyCases().subscribe(data => {
      this.myCasesLogs=this.orgMyCasesLogs=data.map(e=>{
        return{
          id:e.payload.doc.id,
          ...e.payload.doc.data() as {} } as activityLogs
      }) 
      this.myCasesLogs=this.orgMyCasesLogs.filter(x=>{
        // console.log(x)
        if(casesIds.includes(x.caseId)){
         return true;
        }else{
          return false;
        }
       } );
      })
    }
  logout() {
      this.authSrv.signOut();
      // location.reload(true);
  }
  goToComment() {
    this.router.navigateByUrl('comment');
  }
  goToRequest(id) {
    this.afsSrv.updateActivityLogsReaded(id);
    this.router.navigateByUrl('Requests');
  }
  goToCase(id) {
    this.afsSrv.updateActivityLogsReaded(id);
    this.router.navigateByUrl('cases');
  }
  goToConsultationIfYouAreAdmin(caseId,id,active){
    this.afsSrv.updateActivityLogsReaded(id);
    this.router.navigateByUrl('details/'+caseId,{ state: { active: active } });
  }
  goToCommentsIfYouAreEmployee(caseId,id,active){
         this.afsSrv.updateActivityLogsReaded(id);
    this.router.navigateByUrl('details/'+caseId,{ state: { active: active } });
  }
  goToDetails(caseId,id){
    this.afsSrv.updateActivityLogsReaded(id);
    this.router.navigateByUrl('details/'+caseId);
  }
  goToCommentsIfYouAreAdmin(caseId,act) {
    this.router.navigateByUrl('details/'+ caseId,{ state: { active: act } });
  }
  goTOProfile(causerId,id){
    this.afsSrv.updateActivityLogsReaded(id);
    this.router.navigateByUrl('profileDetails/'+causerId);
  }
  setNavItem(){
    if(this.role=="admin") {
      this.navitems=[
        {
          name: 'الموظفين',
          url: '/users',
          icon: 'icon-user',
        },
        {
          name: 'طلبات الحالات',
          url: '/Requests',
          icon: 'icon-docs',
        },
        {
          name: 'جميع الحالات',
          url: '/allCases',
          icon: 'icon-folder',
        },
        {
          name: 'الحالات المنتهية ',
          url: '/endedCases',
          icon: 'icon-folder',
        },
        {
          name: 'المشتركين',
          url: '/profiles',
          icon: 'icon-people',
        },
        {
          name: 'تقارير الحالات',
          url: '/archiveCases',
          icon: 'icon-printer',
        },
        {
          name: 'نشاط العمل',
          url: '/activity',
          icon: 'icon-eyeglass',
        }
        ,
        {
          name: 'عمليات التسجيل الفاشلة',
          url: '/logInErrorLogs',
          icon: 'icon-ban',
        }
        ,
        {
          name: ' استشارات الثويني',
          url: '/consults',
          icon: 'icon-note',
        }
        ,
        
        {
          name: ' قواعد تربوية',
          url: '/educational',
          icon: 'icon-note',
        }
        ,
        {
          name: '  الكتب',
          url: '/books',
          icon: 'icon-book-open',
        }
        ,
    {
      name: '  سجل المدفوعات ',
      url: '/paymentHistory',
      icon: 'icon-docs',
    }
    ,
    {
      name: '  الزواج  ',
      url: '/marrige',
      icon: 'icon-docs',
    }
    ,
    {
      name: ' ألعاب الثويني  ',
      url: '/games',
      icon: 'icon-docs',
    }
      ];
    }
      else if(this.role=="employee"){
      this.navitems=[
        {
          name: 'جميع الحالات',
          url: '/allCases',
          icon: 'icon-folder',
        },
        {
          name: 'الحالات المنتهية ',
          url: '/endedCases',
          icon: 'icon-folder',
        },
        {
          name: 'المشتركين',
          url: '/profiles',
          icon: 'icon-people',
        }
         ,
        {
          name: 'عمليات التسجيل الفاشلة',
          url: '/logInErrorLogs',
          icon: 'icon-ban',
        },
        {
          name: 'استشارات الثويني',
          url: '/consults',
          icon: 'icon-note',
        }
        ,
        
        {
          name: ' قواعد تربوية',
          url: '/educational',
          icon: 'icon-note',
        }
        ,
        
        {
          name: '  الكتب',
          url: '/books',
          icon: 'icon-book-open',
        }
        ,
    {
      name: '  سجل المدفوعات ',
      url: '/paymentHistory',
      icon: 'icon-docs',
    } ,
    {
      name: '  الزواج  ',
      url: '/marrige',
      icon: 'icon-docs',
    }
      ];
    }
  }
}
