import { Injectable, ɵConsole } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireDatabase } from '@angular/fire/database';
import { map } from 'rxjs/operators';
import { Observable, Timestamp } from 'rxjs';
import { EmailValidator } from '@angular/forms';
import { Roles } from './auth.service';
import { idLocale } from 'ngx-bootstrap';
import { toDate } from '@angular/common/src/i18n/format_date';
import { marrigeRoutingModule } from '../views/marrige/marrige-routing.module';



export interface users{
  id
  email
  userName
  uid
  roles:Roles
  createdAt
}
export interface Cases{
  id
  hashId
  uid;
  level;
  category;
  age;
  marital;
  education;
  caseRelation;
  caseHealth;
  caseHealthType
  caseEducation;
  educationType;
  living;
  voiceMessage;
  description;
  attachment;
  email;
  facebook;
  address;
  requests_date;
  readed;
  createdAt
  note;
  proccessedBy;
  processedAt
  employeeEmail
  action
  employeeId
  finishAt
  status
  userId
  userPhone
  updatedAt
  mobile
  requestStatus
}
export interface profiles{
  id;
  name;
  email;
  address;
  mobile;
  userPhone;
  active
  category
  age
  marital
  education
  packetTitle
}


export interface ErrorLogIn{
  id;
  mobile;
  message;
  createdAt;
}
export interface comments{
  id:string;
  caseId:string;
  createdAt:any;
  isReplay:boolean;
  message:string;
  readed:boolean;
  userPhone
  commentBy
  email
}
export interface consulting{
  id;
  caseId;
  createdAt;
  readed;
  consultationBy;
  email;
  message
  approved
}
export interface activityLogs{
  id
  caseId;
  causerId;
  causerType;
  createdAt;
  description;
  logName;
  operation;
  userPhone
  hashId
  readed
}
export interface consults{
  id;
  category;
  question;
  answer;
  createdAt;
  active;
  email
  createdBy;
  hashConsult;

}
export interface educationals{
  id;
  category;
  educational;
  description;
  createdAt;
  active;
  email
  createdBy;
  hashEducational;

}
export interface marrige{
  id;
  category;
  title;
  description;
  createdAt;
  active;
  email
  createdBy;
  hashMarrige;

}
export interface game{
  id;
  category;
  title;
  description;
  createdAt;
  active;
  email
  createdBy;
  hashGame;
  gameUrl
  pdfUrl
  imgCover

}
export interface books{
  id;
  title;
  description;
  pdfUrl;
  imgCover;
  createdAt;
  createdBy;
  email;
  active;
  hashBook
  category;
  paid
  bookUrl
}


@Injectable({
  providedIn: 'root'
})
export class AfsService {
  constructor(private firestore: AngularFirestore,private afStorage:AngularFireStorage,private orgdb: AngularFireDatabase) { }

  getusers(){
    return this.firestore.collection('users').get();
  }
  updateUserName(id,userName){
    return this.firestore.doc('users/' +id).update({userName:userName});
  }
  getUsersByCreatedAt(){
    return this.firestore.collection('users',ref=>{return ref.orderBy("createdAt","desc")}).get();

  }
  
  getEmployeeUsers(){
    return this.firestore.collection('users',ref=>{return ref.where('roles.employee',"==",true)}).get();
  }
  getAdminUsers(){
    return this.firestore.collection('users',ref=>{return ref.where('roles.admin',"==",true)}).get();
  }

      updateRoles(id,roles){
        return this.firestore.doc('users/' +id).update(roles);
      }
      updateRole(id,role){
        return this.firestore.doc('users/' +id).update({role:role});

      }
   
      getCasesHashId(hashId){
        return this.firestore.collection('cases',ref=>{return ref.where("hashId","==",hashId)}).get(); 
      }
      getCasesFromCreatedAt(date){
        return this.firestore.collection('cases',ref=>{return ref.where("createdAt",">=",date).orderBy("createdAt","desc")}).get(); 

      }
      getCasesToCreatedAt(date){
        return this.firestore.collection('cases',ref=>{return ref.where("createdAt","<=",date).orderBy("createdAt","desc")}).get(); 

      }
     
      getCasesFromToCreatedAt(from,to){
        return this.firestore.collection('cases',ref=>{return ref.where("createdAt",">=",from).where("createdAt","<=",to).orderBy("createdAt","desc")}).get(); 

      }
      getCasesFromToCreatedAtLevel(level,from,to){
        return this.firestore.collection('cases',ref=>{return ref.where("level","==",level).where("createdAt",">=",from).where("createdAt","<=",to).orderBy("createdAt","desc")}).get(); 

      }
      getCasesLevel(level){
        return this.firestore.collection('cases',ref=>{return ref.where("level","==",level).orderBy("createdAt","desc")}).get(); 

      }
      getCasesLevelcreatedfrom(level,from){
        return this.firestore.collection('cases',ref=>{return ref.where("level","==",level).where("createdAt",">=",from).orderBy("createdAt","desc")}).get(); 

      }
      getCaseslevelCreatedToDate(level,to){
        return this.firestore.collection('cases',ref=>{return ref.where("level","==",level).where("createdAt","<=",to).orderBy("createdAt","desc")}).get(); 

      }
    
      getCasesHealth(health){
        return this.firestore.collection('cases',ref=>{return ref.where("caseHealth","==",health).orderBy("createdAt","desc")}).get(); 

      }
      getCasesHealthFromCreatedAt(health,from){
        return this.firestore.collection('cases',ref=>{return ref.where("caseHealth","==",health).where("createdAt",">=",from).orderBy("createdAt","desc")}).get(); 

      }
      getCasesHealthtoCreatedAt(health,to){
        return this.firestore.collection('cases',ref=>{return ref.where("caseHealth","==",health).where("createdAt","<=",to).orderBy("createdAt","desc")}).get(); 

      }
      getCasesHealthFromToCreatedAt(health,from,to){
        return this.firestore.collection('cases',ref=>{return ref.where("caseHealth","==",health).where("createdAt",">=",from).where("createdAt","<=",to).orderBy("createdAt","desc")}).get(); 

      }
      getCasesHealthLevel(health,level){
        return this.firestore.collection('cases',ref=>{return ref.where("caseHealth","==",health).where("level","==",level).orderBy("createdAt","desc")}).get(); 

      }
      getCasesHealthLevelFromCreatedAt(health,level,from){
        return this.firestore.collection('cases',ref=>{return ref.where("caseHealth","==",health).where("level","==",level).where("createdAt",">=",from).orderBy("createdAt","desc")}).get(); 

      }
      getCasesHealthLevelToCreatedAt(health,level,to){
        return this.firestore.collection('cases',ref=>{return ref.where("caseHealth","==",health).where("level","==",level).where("createdAt","<=",to).orderBy("createdAt","desc")}).get(); 

      }
      getCasesHealthFromToCreatedAtLevel(health,from,to,level){
        return this.firestore.collection('cases',ref=>{return ref.where("caseHealth","==",health).where("level","==",level).where("createdAt",">=",from).where("createdAt","<=",to).orderBy("createdAt","desc")}).get(); 

      }
      getCasesRelation(Relation){
        return this.firestore.collection('cases',ref=>{return ref.where("caseRelation","==",Relation).orderBy("createdAt","desc")}).get(); 

      }
      getCasesRelationFromCreatedAt(Relation,from){
        return this.firestore.collection('cases',ref=>{return ref.where("caseRelation","==",Relation).where("createdAt",">=",from).orderBy("createdAt","desc")}).get(); 

      }
      getCasesRelationToCreatedAt(Relation,to){
        return this.firestore.collection('cases',ref=>{return ref.where("caseRelation","==",Relation).where("createdAt","<=",to).orderBy("createdAt","desc")}).get(); 

      }
      
      getCasesRelationFromToCreatedAt(Relation,from,to){
        return this.firestore.collection('cases',ref=>{return ref.where("caseRelation","==",Relation).where("createdAt",">=",from).where("createdAt","<=",to).orderBy("createdAt","desc")}).get(); 

      }
      
      getCasesRelationLevel(Relation,level){
        return this.firestore.collection('cases',ref=>{return ref.where("caseRelation","==",Relation).where("level","==",level).orderBy("createdAt","desc")}).get(); 

      }
      getCasesRelationLevelFromCreatedAt(Relation,level,from){
        return this.firestore.collection('cases',ref=>{return ref.where("caseRelation","==",Relation).where("level","==",level).where("createdAt",">=",from).orderBy("createdAt","desc")}).get(); 

      }
      getCasesRelationLevelToCreatedAt(Relation,level,to){
        return this.firestore.collection('cases',ref=>{return ref.where("caseRelation","==",Relation).where("level","==",level).where("createdAt","<=",to).orderBy("createdAt","desc")}).get(); 

      }
      getCasesRelationFromToCreatedAtLevel(Relation,from,to,level){
        return this.firestore.collection('cases',ref=>{return ref.where("caseRelation","==",Relation).where("level","==",level).where("createdAt",">=",from).where("createdAt","<=",to).orderBy("createdAt","desc")}).get(); 

      }
      getCasesRelationFromToCreatedAtLevelHealth(Relation,from,to,level,health){
        return this.firestore.collection('cases',ref=>{return ref.where("caseRelation","==",Relation).where("level","==",level).where("caseHealth","==",health).where("createdAt",">=",from).where("createdAt","<=",to).orderBy("createdAt","desc")}).get(); 

      }
      getCasesRelationHealth(Relation,health){
        return this.firestore.collection('cases',ref=>{return ref.where("caseRelation","==",Relation).where("caseHealth","==",health).orderBy("createdAt","desc")}).get(); 

      }
      getCasesRelationHealthFromCreatedAt(Relation,health,from){
        return this.firestore.collection('cases',ref=>{return ref.where("caseRelation","==",Relation)
        .where("caseHealth","==",health).where("createdAt",">=",from).orderBy("createdAt","desc")}).get(); 

      }
      getCasesRelationHealthToCreatedAt(Relation,health,to){
        return this.firestore.collection('cases',ref=>{return ref.where("caseRelation","==",Relation)
        .where("caseHealth","==",health).where("createdAt","<=",to).orderBy("createdAt","desc")}).get(); 

      }
      getQueryResult(hashId,fromDate,toDate,level,casehealth,caseRelation,requestStatus){
       
        return this.firestore.collection('cases',ref=>{
          let query: firebase.firestore.Query= ref;
          if (hashId) {
            query = query.where('hashId', '==', hashId);
          }
          console.log(requestStatus)
          if (fromDate) {
            query = query.where("createdAt",">=",fromDate);
          }
          if (toDate) {
            query = query.where("createdAt","<=",toDate);
          }
          if (level) {
            query = query.where("level","==",level);
          }
          if (casehealth) {
            query = query.where("caseHealth","==",casehealth);
          }
          if (caseRelation) {
            query = query.where("caseRelation","==",caseRelation);
          }
          if (requestStatus) {
            query = query.where("requestStatus","==",requestStatus);
          }
          return query
        }).get(); 

      }
      getAllCases(){
        return this.firestore.collection('cases',ref=>{return ref.where("status","==",2).limit(10).orderBy("processedAt","desc")}).get(); 
      }

      getAllCasesRequestStatusForEmaployee(){
        return this.firestore.collection('cases',ref=>{return ref.where("requestStatus","<",3).limit(10).orderBy("requestStatus","desc")}).get(); 
      }
      getAllEndedCasesForAdmin(){
        return this.firestore.collection('cases',ref=>{return ref.where("requestStatus","==",20).limit(10).orderBy("processedAt","desc")}).get(); 
      }
      getAllEndedCasesForEmployee(id){
        return this.firestore.collection('cases',ref=>{return ref.where("requestStatus","==",20).where("employeeId","==",id).limit(10).orderBy("processedAt","desc")}).get(); 
      }
      getMyCases(id:string){
        return this.firestore.collection('cases',ref=>{return ref.where("employeeId","==",id).orderBy("processedAt","desc")}).get();
      } 
      getMyRequestsCasesAsAdmin(id:string){
        return this.firestore.collection('cases',ref=>{return ref.where("requestStatus","==",3).where("employeeId","==",id).limit(10).orderBy("processedAt","desc")}).get();

      }
      getMyRequestsCases(id:string){
        return this.firestore.collection('cases',ref=>{return ref.where("requestStatus","<",3).where("employeeId","==",id).limit(10).orderBy("requestStatus","desc")}).get();
      } 
      getAllCasesRequestStatusForCasesToBeApproved(){
        return this.firestore.collection('cases',ref=>{return ref.where("requestStatus","==",4).limit(10).orderBy("processedAt","desc")}).get(); 
      }
      getAllCasesRequestStatusForEndedCases(){
        return this.firestore.collection('cases',ref=>{return ref.where("requestStatus","==",10).limit(10).orderBy("processedAt","desc")}).get(); 
      }
      getAllCasesRequestStatusWaitingForCasesToBeApproved(id){
        return this.firestore.collection('cases',ref=>{return ref.where("requestStatus","==",4).where("employeeId","==",id).limit(10).orderBy("processedAt","desc")}).get(); 
      }
      getAllMyCasesRequestStatusForEndedCases(id){
        return this.firestore.collection('cases',ref=>{return ref.where("requestStatus","==",10).where("employeeId","==",id).limit(10).orderBy("processedAt","desc")}).get(); 
      }
    
    
  
      getCasesFinished(){
        return this.firestore.collection('cases',ref=>{return ref.where("status","==",3).limit(10).orderBy("finishAt","desc")}).get();
      } 
      
      getProfileCases(userId:string){
        return this.firestore.collection('cases',ref=>{return ref.where("userId","==",userId).orderBy("createdAt","desc")}).get();
      } 
      getMyCasesstatussnapshot(id:string){
        return this.firestore.collection('cases',ref=>{return ref.where("employeeId","==",id).where("status","==",2).orderBy("processedAt","desc")}).snapshotChanges();
      } 
      getMyCasesstatussnapshotChanges(id:string){
        return this.firestore.collection('cases',ref=>{return ref.where("employeeId","==",id).orderBy("processedAt","desc")}).snapshotChanges();
      }
      updateRequestStatus(id,requestStatus){
        return this.firestore.collection('cases').doc(id).update({requestStatus:requestStatus});

      }


      getActivityLogsForComments(){
        return this.firestore.collection('activityLogs',ref=>{return ref.where("operation","==",3)
        .where("readed","==",false).orderBy("createdAt","desc")}).snapshotChanges();
      }
//  getActivityLogsForComment(casesIds){
//         return this.firestore.collection('activityLogs',(ref) =>{return ref.where('caseId','in',casesIds)
//         .where("operation","==",3).where("readed","==",false).orderBy("createdAt","desc").limit(10)}).snapshotChanges();
//       }


       getActivityLogsForMyCases(){
        return this.firestore.collection('activityLogs',ref=>{return ref.
          where("operation","==",4).where("readed","==",false).orderBy("createdAt","desc")}).snapshotChanges();
      }
      getActivityLogsAllRequestsCases(){
        return  this.firestore.collection('activityLogs',ref=>{return ref.where("operation","==",1).where("readed","==",false).orderBy("createdAt","desc")}).snapshotChanges();
      }
      getAllActivityLogs(){
        return this.firestore.collection('activityLogs',ref=>{return ref.orderBy("createdAt","desc")}).snapshotChanges();
      }
      getAllActivityLogsWithLimit(){
        return this.firestore.collection('activityLogs',ref=>{return ref.limit(10).orderBy("createdAt","desc")}).get();
      }
  
      getActivityLogsAllNewProfiles(){
        return this.firestore.collection('activityLogs',ref=>{return ref.where("operation","==",7).where("readed","==",false).orderBy("createdAt","desc")}).snapshotChanges();
      }
 
     
      updateStatusCase(id:string){
        return this.firestore.collection('cases').doc(id).update({status:3});
      }
      getProfiles(){
        return this.firestore.collection('profiles').snapshotChanges();
      } 
      getLogInErrorLogs(){
        return this.firestore.collection('LoginErrorLogs').snapshotChanges();
      }
    
 
      getAllMyComments(id:string){
        return this.firestore.collection('comments',ref=>{return ref.where("caseId","==",id).orderBy("createdAt","desc")}).snapshotChanges();
      };
      getAllConsultation(id:string){
        return this.firestore.collection('consulting',ref=>{return ref.where("caseId","==",id).orderBy("createdAt","desc")}).snapshotChanges();
      };
      createComment(comment){
        return this.firestore.collection('comments').add(comment);
      }
      createConsultation(consultation){
        return this.firestore.collection('consulting').add(consultation);
      }
      createCommentNotification(notification){
        return this.firestore.collection('commentsNotification').add(notification);
      }

      updateTheRequest(id,readed,note,processedAt,action,employeeEmail,employeeId,status,updatedAt,requestStatus){
        return this.firestore.collection('cases').doc<Cases>(id).update({readed:readed,note:note,processedAt:processedAt
        ,action:action,employeeEmail:employeeEmail,employeeId:employeeId,status:status,updatedAt:updatedAt,requestStatus:requestStatus});

      }
   
      updateRequestsFinishdate(id,date){
        return this.firestore.collection('cases').doc<Cases>(id).update({finishAt:date});
      }
     
      updateUpdatedAtcases(id,date){
        return this.firestore.collection('cases').doc<Cases>(id).update({updatedAt:date});
      }
      updateActivityLogsReaded(id){
        return this.firestore.collection('activityLogs').doc(id).update({readed:true});
      }
    
      getRequestDetails(id) {
        return this.firestore.collection<Cases>('cases').doc<Cases>(id).valueChanges();
      }
    
      getusersById(id){
        return this.firestore.collection('users').doc<users>(id).valueChanges();
      }
      getProfileById(id){
        return this.firestore.collection('profiles').doc<profiles>(id).valueChanges();
      }
    
      activeProfile(id){
        return this.firestore.collection('profiles').doc<profiles>(id).update({active:true})
      }
      inActiveProfile(id){
        return this.firestore.collection('profiles').doc<profiles>(id).update({active:false})
      }
      updateConsultation(consult: consulting) {
        return this.firestore.doc('consulting/' + consult.id).update(consult);
      }

      updateConsultationReadedApprove(consult:consulting,readed,approved){
        return this.firestore.doc('consulting/' + consult.id).update({readed:readed,approved:approved});

      }
      deleteConsultation(id: string) {
        if (confirm("هل أنت متأكد من رغبتك بحذف الاستشارة ")) {
          this.firestore.doc('consulting/' + id).delete();
        }
      }
   
      getMyCasesLimit(id:string){
     return   this.firestore.collection('cases', ref =>{return ref.where("employeeId","==",id).where("status","==",2).limit(25).orderBy('processedAt', 'desc')}).get();
      }
      getMyCasesٍStatus(id:string){
        return this.firestore.collection('cases',ref=>{return ref.where("employeeId","==",id).where("status","==",3).limit(25).orderBy("finishAt","desc")}).get();
      } 

      getConsults(){
        return this.firestore.collection('consults',ref=>{return ref.orderBy("createdAt","desc")}).get();
      }
      getEducationals(){
        return this.firestore.collection('educational',ref=>{return ref.orderBy("createdAt","desc")}).get();
      }
      getMarrige(){
        return this.firestore.collection('marrige',ref=>{return ref.orderBy("createdAt","desc")}).get();
      }
      getGames(){
        return this.firestore.collection('games',ref=>{return ref.orderBy("createdAt","desc")}).get();
      }
      getPayments(){
        return this.firestore.collection('packetRequests',ref=>{return ref.where("response.Result","==","CAPTURED").orderBy("createdAt","desc")}).get();
      }
      getSpecificConsulting(caseId){
      return this.firestore.collection('consulting',ref=>{return ref.where("caseId","==",caseId).orderBy("createdAt","desc")}).get();

      }
    
        
      
      
      activeCosnult(id){
        return this.firestore.collection('consults').doc<consults>(id).update({active:true})

      }
      inActiveConsult(id){
        return this.firestore.collection('consults').doc<consults>(id).update({active:false})

      }
      activeEducational(id){
        return this.firestore.collection('educational').doc<educationals>(id).update({active:true})

      }
      inActiveEducational(id){
        return this.firestore.collection('educational').doc<educationals>(id).update({active:false})

      }

      activeMarrige(id){
        return this.firestore.collection('marrige').doc<marrige>(id).update({active:true})
      }
      inActiveMarrige(id){
        return this.firestore.collection('marrige').doc<marrige>(id).update({active:false})
      }
      activeGame(id){
        return this.firestore.collection('games').doc<game>(id).update({active:true})
      }
      inActiveGame(id){
        return this.firestore.collection('games').doc<game>(id).update({active:false})
      }
      createConsult(consult){
        return this.firestore.collection('consults').add(consult).then(consult=>{
          this.setHashConsult(consult.id)
        }

        );
      }
      createEducational(educational){
        return this.firestore.collection('educational').add(educational).then(ed=>{
          this.setHashEducational(ed.id)
        }

        );
      }
      createMarrige(marrige){
        return this.firestore.collection('marrige').add(marrige).then(ma=>{
          this.setHashMarrige(ma.id)
        }
        

        );
      }
      createGame(game){
        return this.firestore.collection('games').add(game).then(ma=>{
          this.setHashGame(ma.id)
        }
        

        );
      }
      updateConsult(consult: consults) {
        return this.firestore.doc('consults/' + consult.id).update(consult);
      }
      updateEducational(ed: educationals) {
        return this.firestore.doc('educational/' + ed.id).update(ed);
      }
      updateMarrige(ma: marrige) {
        return this.firestore.doc('marrige/' + ma.id).update(ma);
      }
      updateGame(ga: game) {
        return this.firestore.doc('games/' + ga.id).update(ga);
      }

      setHashConsult(uid){
        let hashConsult = this.firestore.collection('hashConsult').doc('Hash');
        let new_hash=0;
        hashConsult.get().subscribe(profile=>{
        this.firestore.firestore.runTransaction(t => {
         return t.get(hashConsult.ref)
       .then(doc => {
        new_hash = doc.data().main_hash_consult + 1;
        t.update(hashConsult.ref, {main_hash_consult: new_hash});
        this.firestore.doc('consults/' + uid).update({hashConsult: new_hash}); 

              });
          }).then(result => {
            console.log('Transaction Ok:', new_hash);
          }).catch(err => {
            console.log('Transaction failure:', err);
          });
          })
              }
              setHashEducational(uid){
                let hashEducaitonal = this.firestore.collection('hashEducational').doc('Hash');
                let new_hash=0;
                hashEducaitonal.get().subscribe(profile=>{
                this.firestore.firestore.runTransaction(t => {
                 return t.get(hashEducaitonal.ref)
               .then(doc => {
                new_hash = doc.data().hashEducational + 1;
                t.update(hashEducaitonal.ref, {hashEducational: new_hash});
                this.firestore.doc('educational/' + uid).update({hashEducational: new_hash}); 
        
                      });
                  }).then(result => {
                    console.log('Transaction Ok:', new_hash);
                  }).catch(err => {
                    console.log('Transaction failure:', err);
                  });
                  })
                      }


                      setHashMarrige(uid){
                        let hashMarrige = this.firestore.collection('hashMarrige').doc('Hash');
                        let new_hash=0;
                        hashMarrige.get().subscribe(profile=>{
                        this.firestore.firestore.runTransaction(t => {
                         return t.get(hashMarrige.ref)
                       .then(doc => {
                        new_hash = doc.data().hashMarrige + 1;
                        t.update(hashMarrige.ref, {hashMarrige: new_hash});
                        this.firestore.doc('marrige/' + uid).update({hashMarrige: new_hash}); 
                
                              });
                          }).then(result => {
                            console.log('Transaction Ok:', new_hash);
                          }).catch(err => {
                            console.log('Transaction failure:', err);
                          });
                          })
                              }
                              setHashGame(uid){
                                let hashGame = this.firestore.collection('hashGame').doc('Hash');
                                let new_hash=0;
                                hashGame.get().subscribe(profile=>{
                                this.firestore.firestore.runTransaction(t => {
                                 return t.get(hashGame.ref)
                               .then(doc => {
                                new_hash = doc.data().hashGame + 1;
                                t.update(hashGame.ref, {hashGame: new_hash});
                                this.firestore.doc('games/' + uid).update({hashGame: new_hash}); 
                              
                                      });
                                  }).then(result => {
                                    console.log('Transaction Ok:', new_hash);
                                  }).catch(err => {
                                    console.log('Transaction failure:', err);
                                  });
                                  })
                                      }


              getBooks(){
                return this.firestore.collection('books',ref=>{return ref.orderBy("createdAt","desc")}).get();
              }
              createBook(book){
                return this.firestore.collection('books').add(book).then(book=>{
                  this.setHashBook(book.id)
                })
              }
              updateBookPaid(id,paid){
                return this.firestore.collection('books').doc<books>(id).update({paid:paid})
        
              }

              activeBook(id){
                return this.firestore.collection('books').doc<books>(id).update({active:true})
        
              }
              inActiveBook(id){
                return this.firestore.collection('books').doc<books>(id).update({active:false})
        
              }
              updateBook(book: books) {
                return this.firestore.doc('books/' + book.id).update(book);
              }
              uploadBookPdf(event, type: string) {
                if (event) {
                  const randomId = Math.random().toString(36).substring(2);
                  const filePath = `${type}/${randomId}`;
                  return this.afStorage.upload(filePath, event);
            
                }
              }

              uploadImage(event, type: string) {
                if (event) {
                  const randomId = Math.random().toString(36).substring(2);
                  const filePath = `${type}/${randomId}`;
                  return this.afStorage.upload(filePath, event);
            
                }
              }

              
                setHashBook(uid){
                  let hashBook = this.firestore.collection('hashBook').doc('Hash');
                  let new_hash=0;
                  hashBook.get().subscribe(profile=>{
                  this.firestore.firestore.runTransaction(t => {
                   return t.get(hashBook.ref)
                 .then(doc => {
                  new_hash = doc.data().main_hash_book + 1;
                  t.update(hashBook.ref, {main_hash_book: new_hash});
                  this.firestore.doc('books/' + uid).update({hashBook: new_hash}); 
          
                        });
                    }).then(result => {
                      console.log('Transaction Ok:', new_hash);
                    }).catch(err => {
                      console.log('Transaction failure:', err);
                    });
                    })
                        }

                        deleteFromStorage(url){
                          this.afStorage.storage.refFromURL(url).delete();

                        }

        
                        getAges(){
                          return this.firestore.collection('selects').get()

                        }

                      getMyComments(casesIds,key){
                        
                      }

                     
                    }

                   

