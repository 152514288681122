import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

import { Observable, of } from 'rxjs';
import { switchMap} from 'rxjs/operators';

export interface User {
  uid: string;
  email: string;
  displayName?: string;
  roles:Roles
  role

  
}
export interface Roles{
  user?:boolean;
  admin?:boolean;
  employee?:boolean;

}


@Injectable({ providedIn: 'root' })
export class AuthService {

  user: Observable<User>;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router
  ) {

      //// Get auth data, then get firestore user document || null
      this.user = this.afAuth.authState.pipe(
        switchMap(user => {
          if (user) {
            return this.afs.doc<User>(`users/${user.uid}`).valueChanges()
          } else {
            return of (null);
          }
        })
      )
    }

  login(formData?) {
    return this.afAuth.auth.signInWithEmailAndPassword(formData.email, formData.password);
  }
  signIn(email,password){
    return this.afAuth.auth.signInWithEmailAndPassword(email, password);

  }

  getUsersData(user){



  }

  signUp(formData?){
    return this.afAuth.auth.createUserWithEmailAndPassword(formData.email,formData.password);
  }
 
  redirectLogin(){
    return this.afAuth.auth.getRedirectResult();
  }
  signOut() {
    this.afAuth.auth.signOut().then(() => {
      window.localStorage.removeItem("uid");
      window.localStorage.removeItem("role");
      window.localStorage.removeItem("email");
        this.router.navigate(['/login']);
    });
    
  }
  resetPassword(email: string) {
    return this.afAuth.auth.sendPasswordResetEmail(email)
      .then(() => console.log('sent Password Reset Email!'))
      .catch((error) => console.log(error))
  }

   public updateUserData(user) {
    // Sets user data to firestore on login

    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);

    const data: User = {
      uid: user.uid,
      email: user.email,
     roles:{
       
       employee:true
     },
     role:'employee'
      
    }

    return userRef.set(data, { merge: true })

  }



  getRole(user){
    if (user) {
      return this.afs.doc<User>(`users/${user.uid}`).valueChanges()
    } else {
      return of(null)
    }
  }


  canEdit(user:User):boolean{
    const allowed=['admin','employee']
    return this.checkAuthorization(user,allowed)
  }

  canEditEverything(user:User):boolean{
    const allowed=['admin']
    return this.checkAuthorization(user,allowed)
  }
  canEditemployee(user:User):boolean{
    const allowed=['employee'];
    return this.checkAuthorization(user,allowed);
  }









  private checkAuthorization(user:User,allowedRole:string[]):boolean{
    if(!user) return false
    for(const role of allowedRole){
      if(user.roles[role]){
        return true
      }

    }
    return false
  }


}